<template>
  <MediaVideoPlayer
    v-if="actualSrc"
    ref="mediaVideoPlayer"
    :poster="actualPoster"
    :sources="[
      {
        src: actualSrc,
        type: 'application/x-mpegURL',
      },
    ]"
    :autoplay="autoplay"
    :loop="loop"
    class="w-full"
    :controls="controls"
    :fill="true"
    :big-play-button="bigPlayButton"
    :muted="muted"
  />
</template>

<script lang="ts" setup>
import type { MediaVideoPlayer } from '#build/components'
import type { MediaVideoFragment } from '#build/graphql-operations'
import 'video.js/dist/video-js.css'
import { getBasicRokkaUrl } from '~/helpers/rokka'

type MediaVideoProps = Omit<MediaVideoFragment, 'video'> & {
  video?: MediaVideoFragment['video'] | string
} & {
  autoplay?: boolean
  loop?: boolean
  controls?: boolean
  bigPlayButton?: boolean
  poster?: string
  muted?: boolean
}

const props = defineProps<MediaVideoProps>()

const rokkaHash = computed(() =>
  typeof props.video === 'string' ? undefined : props.video?.file?.rokka?.hash,
)
const actualPoster = computed(
  () => props.poster ?? getBasicRokkaUrl(rokkaHash.value),
)
const actualSrc = computed(() =>
  typeof props.video === 'string'
    ? props.video
    : getBasicRokkaUrl(rokkaHash.value, 'm3u'),
)

const mediaVideoPlayer = ref<typeof MediaVideoPlayer>()
const play = () => {
  if (mediaVideoPlayer.value) {
    mediaVideoPlayer.value.play()
  }
}
defineExpose({
  play,
})
</script>
