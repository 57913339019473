export function getBasicRokkaUrl(hash: undefined, filetype?: string): undefined
export function getBasicRokkaUrl(hash: string, filetype?: string): string
export function getBasicRokkaUrl(
  hash: string | undefined,
  filetype?: string,
): string | undefined

export function getBasicRokkaUrl(
  hash: string | undefined,
  filetype: string = 'jpg',
): string | undefined {
  if (hash === undefined) {
    return undefined
  }

  const runtimeConfig = useRuntimeConfig()
  const rokkaHost = runtimeConfig.public.rokkaHost
  const rokkaShortHash = hash.substring(0, 6)

  return `https://${rokkaHost}/dynamic/${rokkaShortHash}.${filetype}`
}
