<template>
  <video ref="videoPlayer" class="video-js" preload="none"></video>
</template>

<script setup lang="ts">
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import type Player from 'video.js/dist/types/player'

// Could not find a proper type for this in video.js >= 8 and @types/video.js is only for <= 7 https://github.com/DefinitelyTyped/DefinitelyTyped/discussions/65641#discussioncomment-6116045
interface VideoJsPlayerOptions {
  autoplay?: boolean
  bigPlayButton?: boolean
  controls?: boolean
  fill?: boolean
  loop?: boolean
  poster?: string
  sources?: {
    src: string
    type?: string
  }[]
  muted?: boolean
  controlBar?: {
    volumePanel?: boolean
  }
  playsinline?: boolean
}

const props = withDefaults(defineProps<VideoJsPlayerOptions>(), {
  poster: undefined,
  sources: undefined,
  controlBar: undefined,
  // playsinline is needed that the video does not pop to fullscreen automatically on mobile phones. Tested on iPhone.
  playsinline: true,
})

// videojs() does not correctly handle objects where properties are set to the value undefined.
// This is why we remove all of them here.
const propsWithOutDefaults = computed(() => {
  const result: VideoJsPlayerOptions = {}

  for (const key in props) {
    const actualKey = key as keyof typeof props
    if (props[actualKey]) {
      // Allow any, because otherwise typescript doesnt get that we are always assign the same keys here.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      result[actualKey] = props[actualKey] as any
    }
  }

  // options which have true as the default need to be set manually here if set to false
  if (!result.bigPlayButton) {
    result.bigPlayButton = false
  }

  // set autoplay always to false. We do not want to play the video if it is not in the viewport. With the intersectionObserver with programmatically press play and pause if autoplay is set to true.
  result.autoplay = false

  return result
})

const videoPlayer = ref<HTMLVideoElement>()
const player = ref<Player>()

watch(propsWithOutDefaults, () => {
  if (player.value) {
    const newProps = propsWithOutDefaults.value

    // I don't know how to auto update all options so we do it manually per option
    player.value.controls(newProps.controls)

    if (player.value.poster() !== newProps.poster) {
      player.value.loadMedia(
        {
          poster: newProps.poster,
        },
        () => {},
      )
    }

    player.value.muted(newProps.muted ?? false)
  }
})

const play = () => {
  if (player.value) {
    player.value.play()
  }
}

defineExpose({
  play,
})

onMounted(() => {
  if (videoPlayer.value) {
    if (player.value) {
      player.value.dispose()
    }

    // on iOS Safari interasectionObsever does not seem to work. We therefore load the video instantly there.
    // from https://stackoverflow.com/a/58065241
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)

    if (isIOS && props.autoplay) {
      propsWithOutDefaults.value.autoplay = true
    }

    player.value = videojs(videoPlayer.value, propsWithOutDefaults.value)

    if (!isIOS) {
      // autoplay only when the video is inside the viewport.
      const intersectionObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (!player.value || !props.autoplay) {
            return
          }

          if (!entry.isIntersecting) {
            if (player.value.muted()) {
              player.value.pause()
            }
          } else {
            player.value.play()
          }
        })
      }, {})

      intersectionObserver.observe(videoPlayer.value)
    }
  }
})
</script>
